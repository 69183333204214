import React from 'react';
import Loadable from 'react-loadable';
import PublicProvider from '@/components/providers/PublicProvider';
import HeaderMetadata from '@/layouts/HeaderMetadata';
import { LoadingPage } from '@/components/loading/loadingBase';

const LoginLoadable = Loadable({
  loader: () => import('@/sections/Login'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});
const LoginPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Sign In" />
      <PublicProvider>
        <LoginLoadable />
      </PublicProvider>
    </React.Fragment>
  );
};

export default LoginPage;
